/*
  Alerts
  for Bootstrap 4.0
  -------------------
  PRH Style Guide
  Version: 2.0
  Created: 3.1.2019
  Last updated: 18.3.2019
*/

th,
.b-table-stacked-md > tbody > tr > [data-label]::before {
  font-family: 'FagoWebPro-Medium';
  font-weight: 500;
  color: $prh-dark-gray;
}
.table-hover tbody tr:hover {
  color: $prh-dark-gray;
}
.prh-table__controls {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}

.prh-table__page-count {
  margin: 0.5rem 1rem;
  font-family: 'FagoWebPro-Medium';
  font-weight: 500;
  color: $prh-dark-gray-87;
}
// Smaller fonts and padding in responsive stacked table
@media (max-width: 768px) {
  .b-table-stacked-md {
    font-size: 0.8rem;
    td {
      padding: 0.25rem;
    }
  }
}