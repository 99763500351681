/*
  PRH Style Guide
Version: 2.0
Created: 3.1.2019
Last updated: 18.3.2019 
  Dropdown
  Bootstrap 4.0
*/

.dropdown-item {
  font-family: 'FagoWebPro-Medium';
  font-weight: 500;
  &:hover {
    text-decoration: underline;
  }
}
.dropdown-toggle:not(.btn) {
  &:hover, &:active {
    span {
      text-decoration: underline;
    }
  }
  &:after {
    border: none;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='white'><polygon points='0,0 100,0 50,50'/></svg>");
    background-repeat: no-repeat;
    background-size: 0.75rem;
    background-position: center 0.6rem;
    background-color: darken($prh-blue, 5%);
    margin-left: 0.5rem;
    width: 1.5rem;
    height: 1.5rem;
    vertical-align: middle;
    border-radius: 2px;
  }
  &:hover:after {
    background-color: darken($prh-blue, 10%);
  }
}