// For the icon buttons
.btn {
  & > *:last-child {
    margin-left: .5rem;
  }
  & > *:first-child {
    margin-right: .5rem;
  }
  .prh-icon {
    height: 1rem;
    width: 1rem;
  }
  .badge {
    line-height: 1;
  }
}


.btn {
  font-family: 'FagoWebPro-Medium';
  font-weight: 500;
  margin-bottom: 6px;
  margin-top: 6px;
  min-width: 7rem;
  letter-spacing: 0.015rem;
}
.btn-fluid {
	margin: 0;
}

.btn-primary {
	background-color: $prh-bright-blue;
	border-color: $prh-bright-blue;
	text-transform: uppercase;
	font-family: 'FagoWebPro-Medium';
	font-weight: 500;
	letter-spacing: 0.06rem;
	&:hover, 
	&:active {
		background-color: $prh-purple;
		border-color: $prh-purple;
	}
	&:disabled,
	&.disabled {
		background-color: $prh-dark-gray-38;
		border-color: $prh-dark-gray-38;
	}
}
  .btn-primary--lowercase {
    text-transform: none;
    letter-spacing: 0.015rem;
  }
.btn-secondary {
	background-color: $white;
	color: $prh-bright-blue;
	border-color: $prh-bright-blue;
	&:hover, 
	&:active {
		background-color: $white;
		border-color: $prh-purple;
		color: $prh-purple;
	}
	&:disabled,
	&.disabled {
		color: $prh-dark-gray-38;
		background-color: $white;
		border-color: $prh-dark-gray-38;
	}
}
.btn-link {
	color: $prh-bright-blue;
	&:hover, 
	&:active {
		color: $prh-purple;
	}
	&:disabled,
	&.disabled {
		color: $prh-dark-gray-38;
		text-decoration: none;
	}
}
  // Used in collapse
  .btn-link--gray {
    background-color: $prh-lightest-gray;
    border: 1px solid $prh-medium-gray;
  }
	.btn-link-fluid {
		padding: 0;
	}
.tooltip-btn {
	border-radius: 9px;
	border-width: 1px;
	width: 18px;
	height: 18px;
	padding: 0;
	position: relative;
	font-size: 12px;
	text-align: center;
	margin: 0 0.5rem 0.25rem 0.5rem;
	line-height: 17px;
	span {
		display: block;
		width: 100%;
		top: 0;
		right: 0;
		position: absolute;
	}
}
.btn-popover,
.btn-tooltip,
.btn-icon {
  padding: 0;
  min-width: 0;
  &.btn-lg .prh-icon {
    height: 2.4rem;
    width: 2.4rem;
    padding: .5rem;
  }
  .prh-icon {
    height: 1.6rem;
    width: 1.6rem;
    padding: .25rem;
    margin: 0;
  }
  label & .prh-icon {
    height: 1.5rem;
  }
}
