.prh-site-header {
	background-color: $prh-blue;
  .prh-site-header__container,
  .prh-site-header__title h2 {
		color: $white;
  }
  .navbar {
    width: 100%;
    color: $white;
    padding: 0 1rem;
    align-items: stretch;
    justify-content: space-between;
  }
  .navbar-collapse {
    flex-wrap: wrap;
    justify-content: flex-start;
    flex-direction: row-reverse;
    align-items: flex-end;
    @media (max-width: 767px ) {
      border-top: 1px solid $prh-bright-blue;
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
    .nav-item,
    .dropdown-item {
      font-family: $font-family-base;
      padding: .5rem 1rem;
      @media (max-width: 767px ) {
        padding: 1rem;
      }
    }
    .dropdown-menu {
      @media (max-width: 767px ) {
        margin: 1rem -1rem;
      }
    }
  }
  .navbar-expand-true .navbar-collapse.show {
    min-height: 80vh;
    
  }

}
.prh-site-header__main-navigation {
  order: 1;
  width: 100%;
  justify-content: flex-end;
}
.prh-site-header__container,
.prh-sub-header__container {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
  max-width: $container-max-width;
  padding: 0;
}
.prh-site-header__title {
	display: flex;
	flex-wrap: nowrap;
	align-items: center;
	h2 {
		font-size: $h3-font-size;
		margin: 0;
	}
	span.sub-title {
		display: block;
		font-size: 0.8rem;
		text-transform: uppercase;
		letter-spacing: 0.06rem;
	}
}
.prh-site-header__logo {
	width: 4rem;
	height: 3rem;
	margin-right: 1rem;
	background-image: url('../images/logo.png');
	background-repeat: no-repeat;
	background-size: contain;
	margin-top: 0.25rem;
	margin-bottom: 0.25rem;
}
.prh-sub-header {
  background-color: $white;
  font-size: 0.9rem;
  padding: 0.25rem 0;
  border-bottom: 1px solid $prh-medium-gray;
  .breadcrumb {
    padding-left: 0;
  }
}