/**
 * Form template styles
 */

.prh-form {
	background-color: $prh-light-blue;
  padding-bottom: 2rem;
  h2 {
		margin-top: 1rem;
		border-top: 1px solid $prh-light-gray;
		padding-top: 1rem;
	}
}
	.prh-form .prh-form__container {
		padding: 1rem;
		max-width: $container-max-width;
		margin: auto;
	}
		.prh-form__container > .prh-section-borders {
			border-top: 4px solid $prh-blue;
			margin: 0;
		}
	.prh-form .container {
		margin-top: 3rem;
		margin-bottom: 3rem;
	}
.prh-form-title {
	border-top: 2px solid $prh-medium-gray;
	min-height: 3rem;
	margin-top: 1rem;
}
/**
 *  Form elements
 */

label, .prh-form__price, .custom-select {
	font-family: 'FagoWebPro-Medium';
}

.form-container {
	padding: 1rem 2rem;
}

/**
 *  Form validation colors
 */

.form-control:invalid, .form-control.is-invalid {
  background-color: rgba( $prh-orange, .08 );
  border-color: rgba( $prh-orange, .50 );
}

/**
 * Custom file uploader styles
**/

.custom-file-label::after {
  text-transform: uppercase;
}