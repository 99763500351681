/*
  Alerts
  for Bootstrap 4.0
  -------------------
  PRH Style Guide
  Version: 2.0
  Created: 3.1.2019
  Last updated: 18.3.2019
*/

.alert {
  color: $prh-dark-gray;
  padding-left: 4rem;
  background-size: 2rem;
  background-position: 1rem 1rem;
  background-repeat: no-repeat;
  min-height: 4rem;
  .lead {
    color: $prh-dark-gray;
    margin-bottom: 1rem;
  }
  .prh-icon {
    position: absolute;
    left: 1rem;
  }
}

.alert-danger {
  background-image: none;
}
.alert-info {
  background-image: none;
}
.alert-success {
  background-color: #daeec6;
  background-image: none;
}
.alert-warning {
  background-color: #f7f0a1;
  background-image: none;
}
.alert-dismissible .close {
  padding-top: .5rem;
}
