/*
  Breadcrumbs
  for Bootstrap 4.0
  -------------------
  PRH Style Guide
  Version: 2.0
  Created: 3.1.2019
  Last updated: 18.3.2019
*/

.breadcrumb-item {
  font-family: $font-family-base;
  a:not(:hover):not(:active) {
    color: $prh-bright-blue;
  }
  .prh-icon {
    margin-right: .5rem;
  }
}