.mrow {
  display: -ms-flexbox; /* IE10 */
  display: flex;
  -ms-flex-wrap: wrap; /* IE10 */
  flex-wrap: wrap;
  padding: 0 4px;

  .column {
    -ms-flex: 16.666665%; /* IE10 */
    flex: 16.666665%;
    max-width: 16.666665%;
    padding: 0 4px;
    img {
      margin-top: 8px;
      vertical-align: middle;
      width: 100%;
      border: 1px solid #5d5d5d;
    }
  }

  @media screen and (max-width: 1024px) {
    .column {
      -ms-flex: 25%;
      flex: 25%;
      max-width: 25%;
    }
  }

  @media screen and (max-width: 800px) {
    .column {
      -ms-flex: 33.33333%;
      flex: 33.33333%;
      max-width: 33.33333%;
    }
  }

  @media screen and (max-width: 400px) {
    .column {
      -ms-flex: 50%;
      flex: 50%;
      max-width: 50%;
    }
  }

  /* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
  @media screen and (max-width: 374px) {
    .column {
      -ms-flex: 100%;
      flex: 100%;
      max-width: 100%;
    }
  }
}

.grid-item {
  margin-top: 8px;
  vertical-align: middle;
  width: 160px;
  padding: 2px;
  border: 1px solid #d9d9d9;
}

.item {
  width: 220px;
  margin: 10px;
  float: left;
}

.grid-sizer {
  width: 33.333%;
}

@media screen and (min-width: 768px) {
  /* 5 columns for larger screens */
  .grid-sizer {
    width: 20%;
  }
}

.masonry {
  margin: 0 auto;
}
