// Set up fonts

/* first for IE 4–8 */
@font-face {
  font-family: 'FagoWebPro-Medium';
  font-weight: 500;
  src: url("../fonts/FagoWebPro-Medium.eot");
}
/* then for WOFF-capable browsers */
@font-face {
  font-family: 'FagoWebPro-Medium';
  font-weight: 500;
  src: url("../fonts/FagoWebPro-Medium.woff") format("woff");
}

#app {
	background-color: $prh-blue;
	min-height: 100vh;
}

#nav {
  background-color: $prh-light-blue;
  border-left: 1px solid $prh-medium-gray;
}
.template-container {
	background-color: $white;
	min-height: calc( 100vh - 20rem );
}

.prh-box {
	border: 1px solid $prh-medium-gray;
	margin-top: 0;
	margin-bottom: -1px;
}
.prh-boxes {
	margin-top: 1rem;
	margin-bottom: 1rem;
}

/**
 * List groups
 */

.prh-simple-list {
	.list-group-item {
		border: 0;
		padding: 0.5rem 0;
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
		align-items: center;
		span:nth-child(1) {
			margin-right: 2rem;
		}
		@supports(display: grid) {
			display: grid;
			grid-template-columns: repeat(auto-fit, minmax(8rem, auto));
			justify-content: flex-start;
			span {
				margin: 0;
			}
		}
	}
	.label {
		font-family: 'FagoWebPro-Medium';
		font-weight: 500;
	}
	*:not(a) > .label {
		color: $prh-dark-gray-50;
	}
}
.prh-price-list {
	.label, .price {
		font-family: 'FagoWebPro-Medium';
		font-weight: 500;
	}
	.price {
		font-size: 1.3rem;
	}
}

/**
 * Instructions box
 */

.prh-form-modal {
	z-index: 1010;
	position: relative;
	@media (max-width: 40rem) {
		width: 95vw;
		margin-left: calc((50% - 95vw/2));
	}
	&.prh-form-modal-fullscreen {
		width: 95vw;
		max-height: 95vh;
		position: fixed;
		top: 2.5vh;
		left: 2.5vw;
		overflow-y: scroll;
		& .prh-form-modal-content {
			width: 100%;
			height: auto;
			margin: 0;
		}
		@media (max-width: 40rem) {
			width: 100vw;
			height: auto;
			max-height: none;
			margin: 0;
			top: 0;
			left: 0;
			bottom:0;
			overflow-y:scroll;
			overflow-x:hidden;
		}
		
	}
}
.prh-form-modal-backdrop {
	position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1010;
	background-color: #000;
	opacity: 0.5;
}
.prh-form-modal-content {
	z-index: 1020;
    position: relative;
    background-color: $white;
}

.success-header {
	display: flex;
	margin-bottom: 1rem;
	justify-content: flex-start;
	align-items: center;
	position: relative;
	.success-header__icon {
		display: none;
		@media (min-width: 500px) {
			display: block;
			width: 4rem;
			margin-left: 0;
			margin-right: 1rem;
		}
		@media (min-width: 768px) {
			width: 6rem;
			margin-left: 0.5rem;
			margin-right: 1.5rem;
		}
	}
}

/* Popover & Tooltip */

.popover-body {
  font-family: $font-family-native;
  font-weight: 400;
}

/* Flex wrapper */
.flex-wrapper {
	display: flex;
	min-height: 100vh;
	flex-direction: column;
	justify-content: space-between
  }
  