.select-btn {
  width: 100%;
  text-align: left;
  border: none;
  background-color: white;
  color: $prh-dark-gray-87;
}

.select-btn-selected {
  width: 100%;
  text-align: left;
  border: none;
  background-color: #ebebeb;
  color: $prh-dark-gray-87;
  padding-bottom: 0.15rem;
  padding-top: 0.15rem;
}

.selection-title {
  font-size: 1.2rem;
  color: #323232;
}

.selection-subtitle {
  font-size: 1.1rem;
  color: #323232;
}

.sort-chevron-icon {
  height: 19px;
  width: 19px;
  vertical-align: text-bottom;
  padding: 0 0.1rem 0.1rem 0.1rem;
  transition: transform 0.3s ease-in-out;
  stroke: $prh-dark-gray-87;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
}

.filter-arrow-icon {
  height: 25px;
  width: 25px;
  vertical-align: text-bottom;
  padding: 0 0.1rem 0.1rem 0.1rem;
  transition: transform 0.3s ease-in-out;
  stroke: $prh-dark-gray-87;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
}
