/*
  Jumbotron
  for Bootstrap 4.0
  -------------------
  PRH Style Guide
  Version: 2.0
  Created: 3.1.2019
  Last updated: 18.3.2019
*/

/* Use this class to pull the first section over the jumbotron */
.jumbotron-flow-under {
	margin-bottom: -5rem;
	padding-top: 5rem;
	padding-bottom: 5rem;
}

.jumbotron .lead {
	color: $prh-blue;
}