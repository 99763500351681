/*
  Typography
  for Bootstrap 4.0
  -------------------
  PRH Style Guide
  Version: 2.0
  Created: 3.1.2019
  Last updated: 18.3.2019
*/

/* Set up the base font sizes */
html {
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @media (min-width: 640px) {
    font-size: 17px;
  }
}

/* Native font stack for paragraphs and inputs */
p,
input,
textarea,
li,
dd {
  font-family: $font-family-native;
  font-weight: 400;
}

/* Set up elements that use the heading fonr */
.lead,
dt {
  font-family: "FagoWebPro-Medium";
  font-weight: 500;
}

/**
 *  Smaller size for headings in mobile
 */

@media (max-width: 768px) {
  h1,
  .h1 {
    font-size: $font-size-base * 1.85;
  }
  h2,
  .h2 {
    font-size: $font-size-base * 1.5;
  }
  h3,
  .h3 {
    font-size: $font-size-base * 1.21;
  }
  h4,
  .h4 {
    font-size: $font-size-base * 1.1;
    &.small {
      font-size: $font-size-base * 0.9;
    }
  }
  h5,
  .h5 {
    font-size: $font-size-base * 1.1;
  }
  h6,
  .h6 {
    font-size: $font-size-base;
  }
}

/* Used in forms to label the form sections */
h4.label,
.prh-label {
  color: $prh-dark-gray-87;
  font-family: $font-family-base;
  font-size: 1rem;
  margin-bottom: 0.1rem;
}

/* Hide screen reader text */
.screen-reader-text {
  @include sr-only;
}
