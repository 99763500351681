/**
 * Containers
 */

 section, .prh-section {
	margin-top: 2rem;
	margin-bottom: 2rem;
	padding: 1rem;
	background-color: $white;
	@media (min-width: 40rem) {
		padding: 1.5rem 2rem;
	}
}

.prh-section-fluid {
	margin: 0;
	padding: 0;
}

.prh-section-borders {
	border: 1px solid $prh-medium-gray;
}
.prh-section-borders-fluid-under {
	margin-bottom: 0;
	border-bottom: 0;
}
.prh-section-borders-fluid-over {
	margin-top: 0;
	border-top: 0;
}