$max: 170px;
$padding: 0.35rem;
$fontSize: 0.95rem;
.prh-table-view {
  thead {
    th {
      font-size: $fontSize;
      max-width: $max;
      vertical-align: top;
      padding: $padding;
    }
  }
  tbody {
    td {
      max-width: $max;
      padding: $padding;
      word-wrap: break-word;
      overflow-wrap: break-word;
    }
  }
}
