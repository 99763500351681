.modal-height {
  max-height: 90vh;
  .img-height {
    max-height: 75vh;
  }
} 

@include media-breakpoint-only(xs) { 
  .modal-height {
    max-height: 97vh;
    .img-height {
      max-height: 85vh;
    }
  }   
}
@include media-breakpoint-only(sm) { 
  .modal-height {
    max-height: 95vh;
    .img-height {
      max-height: 82vh;
    }
  }   
}
@include media-breakpoint-only(md) { 
  .modal-height {
    max-height: 90vh;
    .img-height {
      max-height: 80vh;
    }
  }   
}
@include media-breakpoint-only(lg) { 
  .modal-height {
    max-height: 90vh;
    .img-height {
      max-height: 80vh;
    }
  }   
}

@include media-breakpoint-only(xl) { 
  .modal-height {
    max-height: 90vh;
    .img-height {
      max-height: 80vh;
    }
  } 
}