.prh-list-item {
	display: flex;
	position: relative;
	justify-content: space-between;
	flex-wrap: wrap;
	padding: 1rem 2rem;
}	
	.prh-list-item__data {
		display: flex;
		flex-wrap: wrap;
		width: 100%;
		@media (min-width: 50rem) {
			width: 50%;
			max-width: 30rem;
		}
	}
		.prh-list-item__title {
			margin-top: 1rem;
			order: 1;
			flex-grow: 1;
			h3 a {
				color: $prh-bright-blue;
				font-size: $h4-font-size;
			}
			h3 a:hover,
			h3 a:active {
				color: $prh-purple;
			}
		}
		.prh-list-item__features {
			order: 0;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			flex-grow: 1;
			max-width: 40rem;
			width: 100%;
			span {
				margin-right: 1rem;
				color: $prh-dark-gray-50;
			}
		}
	.prh-list-item__controls {
		align-self: flex-end;
		@media (min-width: 50rem) {
			position: absolute;
			right: 0.5rem;
			top: 0;
		}
	}

	.prh-list-item__status {
		padding-left: 3rem;
		display: block;
		max-width: 30rem;
		p {
			margin: 0;
		}
	}
		.prh-list-item__status-title {
      font-style: italic;
      &.error {
        font-style: normal;
        font-family: 'FagoWebPro-Medium';
        font-weight: 500;
        position: relative;
      }
      &.error:before {
        position: absolute;
        top: -0.25rem;
        left: -2.25rem;
        content: "";
        width: 1.8rem;
        height: 1.8rem;
        background-image: url('data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiAgd2lkdGg9IjI0IiAgaGVpZ2h0PSIyNCIgIHZpZXdCb3g9IjAgMCAyNCAyNCIgIGZpbGw9Im5vbmUiICBzdHJva2U9IiNmYjg2M2EiICBzdHJva2Utd2lkdGg9IjIiICBzdHJva2UtbGluZWNhcD0icm91bmQiICBzdHJva2UtbGluZWpvaW49InJvdW5kIj4gIDxjaXJjbGUgY3g9IjEyIiBjeT0iMTIiIHI9IjEwIiAvPiAgPGxpbmUgeDE9IjEyIiB5MT0iOCIgeDI9IjEyIiB5Mj0iMTIiIC8+ICA8bGluZSB4MT0iMTIiIHkxPSIxNiIgeDI9IjEyIiB5Mj0iMTYiIC8+PC9zdmc+');
        background-size: contain;
      }
    }
