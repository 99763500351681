/*
  Navigation
  for Bootstrap 4.0
  -------------------
  PRH Style Guide
  Version: 2.0
  Created: 3.1.2019
  Last updated: 18.3.2019
*/

.nav-link:hover,
.nav-link:active {
  text-decoration: underline;
}

.nav-tabs {
  font-family: 'FagoWebPro-Medium';
  font-size: 0.8rem;
  border-bottom: 0;
  width: calc(100% + 2rem);
  margin-left: -1rem;
  @media (min-width: 40rem) {
    margin-left: -2rem;
    width: calc(100% + 4rem);
  }
  .nav-link {
    color: $prh-dark-gray-87;
    padding: .75rem 0;
    border: 0;
    border-bottom: 3px solid $prh-medium-gray;
    position: relative;
    &.arrow {
      font-size: 1.2rem;
      line-height: 1rem;
      font-weight: 400;
    }
    &.active {
      border-color: transparent;
      border-bottom: 3px solid $prh-purple;
      color: $prh-purple;
    }
    &:disabled,
    &:disabled:hover,
    &:disabled:active,
    &.disabled,
    &.disabled:hover,
    &.disabled:active {
      color: $prh-dark-gray-38;
      border-bottom: 3px solid $prh-medium-gray;
    }
    &:hover,
    &:active {
      text-decoration: none;
      border-bottom: 3px solid $prh-purple;
      color: $prh-purple;
    }
    &.error {
      @media (min-width: 768px) {
        padding-right: 1.2rem;
      }
    }
    &.error:before {
      position: absolute;
      top: 0.6rem;
      right: 0;
      content: "";
      width: 1.2rem;
      height: 1.2rem;
      background-image: url('data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiAgd2lkdGg9IjI0IiAgaGVpZ2h0PSIyNCIgIHZpZXdCb3g9IjAgMCAyNCAyNCIgIGZpbGw9Im5vbmUiICBzdHJva2U9IiNmYjg2M2EiICBzdHJva2Utd2lkdGg9IjIiICBzdHJva2UtbGluZWNhcD0icm91bmQiICBzdHJva2UtbGluZWpvaW49InJvdW5kIj4gIDxjaXJjbGUgY3g9IjEyIiBjeT0iMTIiIHI9IjEwIiAvPiAgPGxpbmUgeDE9IjEyIiB5MT0iOCIgeDI9IjEyIiB5Mj0iMTIiIC8+ICA8bGluZSB4MT0iMTIiIHkxPSIxNiIgeDI9IjEyIiB5Mj0iMTYiIC8+PC9zdmc+');
      background-size: contain;
    }
  }
}
