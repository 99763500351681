.prh-cards {
	display: flex;
	flex-wrap: wrap;
	@supports (display: grid) {
	  display: grid;
	  grid-template-columns: repeat(auto-fit, minmax(auto, 16rem));
	  grid-gap: 1rem 3rem;
	}
	.card {
	  width: calc(100% / 3);
	  min-width: 15rem;
	  padding: 2rem;
	  @supports (display: grid) {
		width: auto;
		min-width: initial;
		padding: 0;
	  }
	  border: 0;
	  //border-left: 1px solid $prh-light-gray;
  
	  &:first-child,
	  &:nth-child(4n + 0) {
		border-left: 0;
	  }
	}
	.card-img-top {
	  width: 6rem;
	  height: 6rem;
	  padding: 0.5rem;
	  border-radius: 3rem;
	  background-color: $prh-pale-blue;
	  img {
		width: 100%;
		max-height: 5.5rem;
		max-width: 5.5rem;
		height: auto;
		filter: grayscale(100%);
		opacity: 0.8;
	  }
	}
	.purple.card-img-top {
	  background-color: rgba($prh-medium-purple, 0.5);
	}
	.card-body {
	  display: flex;
	  flex-direction: column;
	  justify-content: space-between;
	  padding: 1rem 0;
	}
}
  
.prh-small-cards {
	display: flex;
	flex-wrap: wrap;
	@supports (display: grid) {
	  display: grid;
	  padding: 1rem;
	  grid-template-columns: repeat(auto-fit, minmax(auto, 15rem));
	  grid-gap: 1rem;
	  justify-content: center;
	}
	.card {
	  width: calc(100% / 4);
	  min-width: 10rem;
	  @supports (display: grid) {
		width: auto;
		min-width: initial;
	  }
	}
	.card-text {
	  font-size: 0.9rem;
	  font-family: "FagoWebPro-Medium";
	  text-align: center;
	}
	.card-img-top {
	  margin: 0 auto;
	}
}
  
.datafield-content {
	color: #323232;
	font-family: "FagoWebPro-Medium";
	overflow-wrap: break-word;
}
  