/*
  Footer 
  ------------------
  PRH Style Guide
  Version: 2.0
  Created: 3.1.2019
  Last updated: 18.3.2019
*/

 .prh-site-footer {
  flex-shrink: 0;
	background-color: $prh-blue;
	.container {
		max-width: $container-max-width;
	}
  p,
  small,
  .small {
		color: $white;
		font-family: 'FagoWebPro-Medium';
		font-weight: 500;
		margin-bottom: .5rem;
  }
  a:not(.btn) {
    color: $prh-pale-blue;
  }
  h4,
  .h4 {
		color: $prh-pale-blue;
  }
}
.prh-site-footer__logo {
	width: 4rem;
  height:4rem;
  margin: 0;
	background-image: url('../images/logo.png');
	background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
