._alfame-datepicker-wrapper,
._alfame-datepicker-wrapper > div.react-datepicker-wrapper,
._alfame-datepicker-wrapper
  > div
  > div.react-datepicker__input-container
  ._alfame-datepicker-wrapper
  > div
  > div.react-datepicker__input-container
  input {
  width: 100%;
}

.react-datepicker__input-container {
  width: inherit;
}

.react-datepicker-wrapper {
  width: 100%;
}
