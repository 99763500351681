.classic-shadow {
    box-shadow: 
    0 13px 27px -5px rgba(50,50,93,.25),
    0 8px 16px -8px rgba(0,0,0,.3),
    0 -6px 16px -6px rgba(0,0,0,.025);
}

.smooth-1 {
    box-shadow: 0 1px 2px rgba(0,0,0,0.2), 
                0 2px 2px rgba(0,0,0,0.08);
  }

.smooth-2 {
    box-shadow: 0 1px 2px rgba(0,0,0,0.2), 
                0 2px 2px rgba(0,0,0,0.06), 
                0 4px 4px rgba(0,0,0,0.05);
  }

  .smooth-3 {
    box-shadow: 0 1px 2px rgba(0,0,0,0.2), 
                0 2px 2px rgba(0,0,0,0.06), 
                0 4px 4px rgba(0,0,0,0.05), 
                0 8px 8px rgba(0,0,0,0.04);
  }

  .smooth-4 {
    box-shadow: 0 1px 2px rgba(0,0,0,0.2), 
                0 2px 2px rgba(0,0,0,0.06), 
                0 4px 4px rgba(0,0,0,0.05), 
                0 8px 8px rgba(0,0,0,0.04),
                0 16px 16px rgba(0,0,0,0.03);
  }
  
  .smooth-5 {
    box-shadow: 0 1px 2px rgba(0,0,0,0.2), 
                0 2px 2px rgba(0,0,0,0.06), 
                0 4px 4px rgba(0,0,0,0.05), 
                0 8px 8px rgba(0,0,0,0.04),
                0 16px 16px rgba(0,0,0,0.03),
                0 32px 32px rgba(0,0,0,0.02);
  }